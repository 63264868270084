@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0px;
  padding: 0px;
  list-style: none;
  text-decoration: none;
  outline: none;
  border: 0px;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.5px;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

html {
  scroll-behavior: smooth;
}

/* == SCROLL BAR == */
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: #050718;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #fd3e45;
}

/* == || SCROLL BAR == */
/* == GENERAL == */
.container {
  width: 100%;
  max-width: 1400px !important;
  margin: auto;
  padding: 0px 15px;
}

/* == || GENERAL == */

/* == Header == */
header {
  height: fit-content;
  width: 100%;
}

.header-container {
  padding: 10px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.header-contacts {
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.header-contacts .contact {
  padding: 10px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 20px;
}

.header-contacts .contact .pad {
  padding: 0px 5px;
}

.header-contacts .contact p:nth-child(1) {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #1c1b1f;
}

.header-contacts .contact p:nth-child(2) {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1c1b1f;
}

nav {
  width: 100%;
  border-top: 1px solid rgba(28, 27, 31, 0.2);
}

.nav-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.logo h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 90px;
  /* or 150% */

  text-align: center;

  color: #c84802;
}

.nav-container .logo {
  display: none;
}

.links {
  height: 100%;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.links a {
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  padding: 10px 10px;
}

.links .active-link {
  color: #25518c;
}

.links a:hover {
  color: #25518c;
}

.nav-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #c84802;
}

.nav-logo img {
  margin: 0px 10px 0px;
}

.menu-bar {
  padding: 5px;
}

.menu-btn {
  font-size: 28px;
  padding: 10px 20px;
}

/* == || Header == */
/* == BANNER == */
section.banner {
  height: fit-content;
  min-height: 800px;
  background-image: url(../public/images/home/banner.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.banner-mask {
  background-color: #0000009c;
  height: 100%;
  width: 100%;
  min-height: 800px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner-text {
  width: 100%;
  max-width: 1200px;
  margin: auto;
}
.banner-text:hover {
  scale: 1.02;
}

.banner-text h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  text-align: center;
  color: #fcfcfc;
}

.banner-text p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  text-align: center;
  color: #fcfcfc;
  margin: 20px 0px;
}

.banner-text button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  background: #fcfcfc;
  font-size: 24px;
  border-radius: 5px;
  margin: auto;
}

/* == || BANNER == */
/* == UPCOMING EVENTS == */
.upcoming-events {
  background-color: #eff1fb;
  padding: 60px 0px;
}

.upcoming-container {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.upcoming-container .up-left,
.upcoming-container .up-right {
  padding: 25px 0px;
}

.up-left .top {
  padding: 25px 0px;
}

.up-left .top h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  color: #1c1b1f;
}

.up-left .bottom {
  width: 100%;
  max-width: 1000px;
  min-width: 320px;
  padding: 25px 5px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.bottom .up-card {
  background: #fcfcfc;
  /* shadow */
  box-shadow: 10px 32px 72px rgba(51, 51, 51, 0.04);
  border-radius: 5px;
  height: 100%;
  max-height: 382px;
  min-height: 278px;
  width: fit-content;
  max-width: 384px;
  min-width: 260px;
  padding: 20px;
  position: relative;
  margin: 10px 5px;
}

.bottom .up-card img {
  width: 100%;
}

.bottom .up-card .floating-card {
  position: absolute;
  top: 40px;
  right: 40px;
  height: fit-content;
  width: 80%;
  padding: 10px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.floating-card .hold {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.floating-card span.price {
  height: 40px;
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #c84802;
}

.floating-card span.share,
.floating-card span.love {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  margin: 0px 8px;
  color: #1c1b1f;
}

.floating-card span i {
  font-size: 20px;
  color: #1c1b1f;
}

.up-card-info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 16px 0px;
}

.up-card-info .left {
  padding: 10px 0px 0px;
}

.up-card-info .left span:nth-child(1) {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #1c1b1f;
}

.up-card-info .left span:nth-child(3) {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  color: #1c1b1f;
}

.up-card-info .right {
  padding: 0px 0px 0px 20px;
}

.up-card-info .right a {
  text-decoration: none;
  cursor: pointer;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #c84802;
}

.up-card-info .right span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1c1b1f;
}

.bottom-more {
  padding: 20px 0px;
}

.bottom-more button {
  padding: 16px 24px;
  height: 50px;
  background: #fcfcfc;
  border-radius: 5px;
  outline: none;
  border: 0px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #c84802;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.up-right {
  max-width: 384px;
  width: 100%;
  min-width: 280px;
}

.up-right .top {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 25px 0px;
}

.up-right .top span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
  color: #1c1b1f;
}

.up-right .top a {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: #c84802;
  padding: 18px 10px 18px;
}

.up-right .news {
  padding: 10px 0px;
}

.up-right .news .top {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.up-right .news .top .left {
  width: 149px;
  height: 34px;
  background: #c84802;
  border-radius: 6px 0px 0px 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.up-right .news .top .left span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 120%;
  /* identical to box height, or 16px */
  text-align: center;
  text-transform: uppercase;
  color: #fcfcfc;
}

.up-right .news .top .right {
  width: 149px;
  height: 34px;
  background: #fcfcfc;
  border-radius: 0px 6px 6px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.up-right .news .top .right span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 120%;
  /* identical to box height, or 16px */
  text-align: center;
  text-transform: uppercase;
  color: #1c1b1f;
}

.up-right .news .bottom h4 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  color: #1c1b1f;
  margin: 0px 0px 5px;
}

.up-right .news .bottom p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1c1b1f;
  margin: 10px 0px;
}

/* == || UPCOMING EVENTS == */
/* == OFFERS == */
.offers {
  padding: 60px 0px;
}

.offer-head {
  padding: 40px 0px 20px;
}

.offer-head h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
  text-align: center;
  color: #1c1b1f;
}

/* .offer-content {
  padding: 10px 0px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
} */

.offer-card {
  z-index: 0;
  position: relative;
  margin: 30px 0px;
}

.offer-card img {
  position: relative;
  z-index: 0;
  width: 100%;
  height: 320px;
  border-radius: 8px;
}

.offer-info {
  height: 274px;
  width: 94%;
  background: #fcfcfc;
  border: 1px solid #e0e2f1;
  border-radius: 5px;
  position: relative;
  z-index: 5;
  margin: -60px auto 0px;
  text-align: center;
  padding: 22px 0px 0px;
}

.offer-info span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  color: #1c1b1f;
  margin: 10px auto;
}

.offer-info h3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #1c1b1f;
  margin: 10px auto;
}

.offer-info p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
  color: #1c1b1f;
  margin: 10px auto;
  max-width: 90%;
}

.offer-info button {
  background: #c84802;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: 0px;
  position: absolute;
  bottom: -25px;
  left: 44%;
}

/* == || OFFERS == */
/* == Projects == */
.projects {
  padding: 60px 0px;
  background: #eff1fb;
  border-radius: 5px;
}

.projects-head {
  padding: 40px 0px 20px;
}

.projects-head h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
  text-align: center;
  color: #1c1b1f;
}

.projects-content {
  padding: 20px 15px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.project {
  height: fit-content;
  width: 100%;
  max-width: 584px;
  min-width: 280px;
  margin: 10px;
  border: 1px solid #e0e2f1;
  border-radius: 5px;
}

.project img {
  width: 100%;
}

.project .project-info {
  padding: 50px 30px;
}

.project-info h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #1c1b1f;
  margin: 10px 0px;
}

.project-info p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1c1b1f;
  margin: 10px 0px;
}

.project-info button {
  outline: none;
  border: 0;
  width: 95%;
  height: 50px;
  background: #c84802;
  border-radius: 5px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #fcfcfc;
  margin: 10px auto;
}

/* == || Projects == */
/* == Think == */
.think-tanks {
  padding: 60px 0px;
}

.think-head {
  padding: 40px 0px 20px;
}

.think-head h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
  text-align: center;
  color: #1c1b1f;
}

.think-tank-content {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.think-tank-card {
  height: fit-content;
  width: 100%;
  max-width: 584px;
  min-width: 280px;
  margin: 10px;
}

.think-tank-card img {
  width: 100%;
}

.think-tank-info {
  padding: 100px 0px 50px;
}

.think-tank-info h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #1c1b1f;
  margin: 10px 0px;
}

.think-tank-info p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1c1b1f;
  margin: 10px 0px;
}

.think-tank-info button {
  outline: none;
  border: 0;
  width: 95%;
  height: 50px;
  background: #c84802;
  border-radius: 5px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #fcfcfc;
  margin: 10px auto;
}

/* == || Think == */
/* == Contact == */
section.contact {
  padding: 60px 0px;
  background-color: #eff1fb;
}

.contact-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.contact-details,
.contact-form {
  padding: 10px;
  width: 100%;
  max-width: 584px;
  min-width: 280px;
}

.contact-details .title {
  padding: 0px 0px 16px;
}

.contact-details .title h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 49px;
  color: #1c1b1f;
  margin: 10px 0px;
}

.contact-details .title p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #de4404;
  margin: 10px 0px;
}

.contact-details .details {
  padding: 16px 0px;
}

.details .detail {
  height: 48px;
  width: fit-content;
  padding: 12px 24px;
  background: #eff1fb;
  border: 1px solid #e0e2f1;
  border-radius: 5px;
  margin: 16px 0px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.details .detail span {
  margin: 0px 16px 0px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #1c1b1f;
}

.contact-details iframe {
  margin: 30px 0px 10px;
  width: 100%;
  max-width: 560px;
  min-width: 260px;
  height: 100%;
  max-height: 287px;
  aspect-ratio: 560/287;
}

.contact-form form .names {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

form .control-group {
  width: 100%;
  max-width: 564px;
  min-width: 260px;
  padding: 10px;
}

form .control-group label {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #1c1b1f;
  padding: 0px 0px 10px;
}

form .control-group input {
  outline: none;
  width: 100%;
  height: 50px;
  border: 1px solid #e0e2f1;
  border-radius: 5px;
  background-color: #eff1fb;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #1c1b1f;
  padding: 13px 20px;
}

form .control-group input::placeholder,
form .control-group textarea::placeholder {
  color: #d9d9d9;
}

form textarea {
  height: 150px;
  width: 100%;
  border: 1px solid #e0e2f1;
  border-radius: 5px;
  background-color: #eff1fb;
  max-width: 564px;
  min-width: 260px;
  padding: 13px 20px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #1c1b1f;
}

.control-group.checkbox {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.control-group.checkbox label {
  font-size: 12px;
  line-height: 18px;
  margin: 0px 0px 0px 10px;
}

#checkboxed {
  width: 18px !important;
  height: 18px !important;
  border: 2px solid #e0e2f1 !important;
  border-radius: 2px !important;
  background-color: #eff1fb !important;
}

.control-group button {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #fcfcfc;
  height: 50px;
  width: 100%;
  background: #c84802;
  border-radius: 5px;
}

/* == || Contact == */
/* == Footer == */
footer {
  height: fit-content;
  padding: 80px 0px 20px;
  background: #231e28;
}

.footer-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}

.col {
  width: 100%;
  margin: 10px;
}

.col.one {
  max-width: 600px;
  min-width: 280px;
}

.col.one h4 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 610;
  font-size: 22px;
  line-height: 36px;
  color: #fcfcfc;
  margin: 10px 0px;
}

.col.one p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #fcfcfc;
  margin: 10px 0px 13px;
}

.col.two,
.col.three {
  max-width: 300px;
  min-width: 280px;
  margin: 10px;
}

.col h4 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  color: #fcfcfc;
  margin: 10px 0px;
}

.col ul {
  margin: 30px 0px 0px;
}

.col ul li {
  padding: 5px 0px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.col ul li a {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #fcfcfc;
}

.bottom-footer p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #fcfcfc;
  margin: 10px;
}

/* == || Footer == */

/* == ABOUT == */
section.about,
section.about .banner-mask {
  min-height: 400px;
}

.about-banner-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.banner-text {
  width: 100%;
  max-width: 1200px;
  margin: auto;
}

section.about .banner-text h1,
section.about .banner-text p {
  text-align: left !important;
}

.why {
  background: #eff1fb;
  padding: 60px 0px;
}

.why-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.why-container .left {
  width: 80%;
}

.why-container .left p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  color: #1c1b1f;
  margin: 0px 0px 5px;
  text-align: center;
}

.why-container .left h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
  color: #c84802;
  margin: 5px 0px 10px;
  text-align: center;
}

.why-container .left p:nth-child(3) {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 24px;
  color: #1c1b1f;
  margin: 10px 0px;
}

.why-checked {
  width: 100%;
}

.check-ctrl {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.check-ctrl .check {
  width: 100%;
  max-width: 300px;
  min-width: 280px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 0px;
}

.check-ctrl .check span {
  padding: 0px 0px 0px 20px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1c1b1f;
}

.why-container .right {
  padding: 0px 10px;
}

.why-container .right img {
  width: 100%;
  min-width: 280px;
}

.missions {
  padding: 80px 0px;
  background-color: #ffffff;
}

.missions-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.missions-container .mission {
  max-width: 390px;
  width: 100%;
  min-width: 280px;
  min-height: 232px;
  height: fit-content;
  padding: 20px;
  margin: 10px 0px;
}

.missions-container .mission h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  margin: 0px 0px 10px;
  text-align: left;
}

.missions-container .mission.one h2,
.missions-container .mission.two h2 {
  color: #fcfcfc;
}

.missions-container .mission.three h2 {
  color: #1c1b1f !important;
}

.missions-container .mission p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #fcfcfc;
  margin: 10px 0px;
  text-align: left;
}
.value-card h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height, or 150% */

  color: #fcfcfc;
}
.value-card p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  /* or 150% */

  color: #fcfcfc;
}

.mission li {
  padding: 5px 0px;
}

.mission li span:nth-child(1) {
  width: 40px !important;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  color: #1c1b1f;
}

.mission li span:nth-child(2) {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1c1b1f;
  padding: 0px 0px 0px 18px;
}

.mission.one {
  background: #25518c;
}

.mission.two {
  background: #de4404;
}

.mission.three {
  background: #eff1fb;
}

section.choices {
  height: fit-content;
  background-image: url(../public/images/home/Digital-Product-Engineering.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.choices-mask {
  background: linear-gradient(
    0deg,
    rgba(28, 27, 31, 0.75),
    rgba(28, 27, 31, 0.75)
  );
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 0px 40px;
}

.choice-head {
  padding: 60px 0px;
}

.choice-head p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #fcfcfc;
}

.choice-content {
  padding: 20px 0px 20px;
}

.choice-content .row {
  padding: 0px 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}

.choice-head h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
  text-align: center;
  color: #fcfcfc;
}

.column.choice {
  width: 100%;
  min-width: 280px;
  max-width: 390px;
  min-height: 352px;
  height: fit-content;
  border: 3px solid #e0e2f1;
  padding: 40px;
  margin: 10px 0px;
}

.choice-content .column {
  width: 100%;
  min-width: 280px;
  max-width: 390px;
  height: fit-content;
}

.column.choice h3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #fcfcfc;
}

.column.choice p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #fcfcfc;
}

section.team {
  background: #231e28;
  padding: 60px 0px;
}

.team-head {
  padding: 20px 0px;
}

.team-head p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #fcfcfc;
}

.team-head h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
  text-align: center;
  color: #fcfcfc;
}

.team-content {
  padding: 20px 10px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.team-content .member {
  height:fit-content;
  width: 100%;
  max-width: 290px;
  min-width: 280px;
  margin: 10px 0px;
}

.team-content .member img {
  width: 100%;
  height: 350px;
  object-fit: fill;
}

.team-content .member-info {
  width: 100%;
  padding: 20px;
  background-color: #ffffff;
}

.member-info span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #1c1b1f;
}
.member-info i{
  font-size: 20px;
}
.member-info i:hover{
  font-size: 20px;
  color: #c84802;
}
.member-info h3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #c84802;
  margin: 0px 0px 10px;
}
.text-container{
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.member-info p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1c1b1f;
  margin: 10px 0px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.member-info a {
  text-decoration: none;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #c84802;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 10px 0px;
}
.member-info a svg {
  margin: 0px 0px 0px 10px;
}
section.contact.about,
section.contact.about .detail,
section.contact.about .contact-form input,
section.contact.about .contact-form textarea {
  background-color: #ffffff !important;
}
.team-container button{
  display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 24px;
gap: 10px;
width: 187px;
height: 60px;
}
/* == || ABOUT == */



/* == RESPONSIVE == */
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 994px) {
  .why-container .left p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
    color: #1c1b1f;
    margin: 0px 0px 5px;
    text-align: start;
  }
  header {
    display: none;
  }

  nav {
    height: 120px;
  }

  .nav.logo {
    display: block;
    background: #ffffff;
  }

  .menu-btn {
    color: #1c1b1f;
  }

  .links {
    height: 100vh;
    width: 70%;
    position: fixed;
    top: 0;
    right: -100%;

    display: block;
    padding: 80px 0px 0px;
    text-align: centers;
    transition: all ease 0.5s;

    z-index: 5;
    background-color: #ffffff;
    overflow: auto;
  }


  .links a {
    display: flex;
    align-items: center;
    justify-content: start;
    width: 100% !important;
    height: 50px;
    color: #1c1b1f;
    padding: 0px 50px 0px;
  }

  .links a:hover {
    background-color: #de4404;
    color: #ffffff;
  }

  .links span {
    cursor: pointer;
  }

  .links.active {
    right: 0%;
  }

  .cancel-btn {
    height: 40px;
    width: 40px;
    border-radius: 20px;
    box-shadow: 2px 2px 12px #0e315533;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #1c1b1f;
    font-size: 18px;
    position: absolute;
    top: 12px;
    right: 20px;
  }

  .banner-text h1 {
    font-size: 40px;
    line-height: 50px;
  }

  .banner-text p {
    font-size: 20px;
    line-height: 30px;
  }

  .banner-text button {
    width: 90%;
    max-width: 320px;
  }

  .upcoming-container {
    justify-content: space-evenly;
  }

  .up-left .top h1 {
    font-size: 25px;
    line-height: 45px;
  }

  .up-left .bottom {
    justify-content: space-evenly;
  }

  .bottom-more button {
    width: 100%;
    max-width: 384px;
    min-width: 280px;
  }
}

/* Extra small devices (phones, 600px and down) */
@media screen and (max-width: 640px) {
  section.banner {
    height: fit-content;
    background-image: url(../public/images/home/banner.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  
  .banner-mask {
    background-color: #0000009c;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: start;
    justify-content: start;
  }
  
  .banner-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .banner-text h1 {
    font-size: 30px;
    line-height: 35px;
  }

  .banner-text p {
    font-size: 16px;
    line-height: 33px;
  }

  .banner-text button {
    width: 100%;
    max-width: 384px;
  }

  .up-left .top h1 {
    font-size: 30px;
    line-height: 35px;
  }

  .bottom .up-card {
    max-width: 320px;
  }

  .bottom-more button {
    width: 90%;
    max-width: 320px;
    min-width: 250px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .links {
    padding: 0px 20px;
    display: block;
    display: flex;
    align-items: center !important;
    justify-content: space-between;
  }

  nav {
    height: 50px;
    background: #c84802;
  }

  .menu-btn {
    color: #ffffff;
  }

  .nav.logo {
    display: none;
  }

  .menu-bar {
    display: none !important;
  }

  .cancel {
    display: none;
  }

  .banner-text h1 {
    font-size: 60px;
    line-height: 90px;
  }

  .banner-text p {
    font-size: 24px;
    line-height: 36px;
  }

  .banner-text button {
    width: 100%;
    max-width: 384px;
  }

  .up-left .top h1 {
    font-size: 40px;
    line-height: 60px;
  }

  .bottom-more button {
    width: 100%;
    max-width: 384px;
    min-width: 280px;
  }

  form .control-group.name {
    width: 100%;
    max-width: 280px;
  }

  .upcoming-container {
    justify-content: space-between;
  }

  .up-left .bottom {
    justify-content: space-between;
  }
}

/* == || RESPONSIVE == */
@media only screen and (max-width:768px) {
  .header-container {
    padding: 10px 0px;
    display: none
  }
  .logo-sm{
    color: #050718;
    display: block;
  }
  .links a {
    font-size: 16px;
    font-weight: 500;
    color: #050718;
    padding: 10px 10px;
  }
  .links {
    height: fit-content;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
}
